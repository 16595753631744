<template>
  <div id="knowledgeBase" v-wechat-title='title'>
    <LeftTypeChoose type="knowledgeBase"></LeftTypeChoose>
    <div class="content">
      <CommonTitle :noShow="true">
        <template v-slot:title>{{ $route.params.type == 'POLICY' ? '政策法规' : $route.params.type == 'STANDARD' ? '标准' : $route.params.type == 'BOOK' ? '书籍' : '课程' }}</template>
        <template v-slot:search>
          <el-input 
            v-model="value" 
            placeholder="请输入关键词" 
            size="large"
            @keydown="keydownClick"
            @input="inputClick">
            <template #suffix>
              <svg-icon icon-class="search" @click="searchClick"></svg-icon>
            </template>
          </el-input>
        </template>
      </CommonTitle>
      <div class="inner">
        <template v-if="dataList.length > 0">
          <KnowledgeBaseList :dataList="dataList"></KnowledgeBaseList>
          <Pagination 
            :pageSize="pageSize"
            :number="total"
            :pageNumber="pageNumber"
          ></Pagination>
        </template>
        <NoData v-else></NoData>  
      </div>
    </div>
  </div>
</template>

<script>
import LeftTypeChoose from './components/LeftTypeChoose'
import KnowledgeBaseList from './components/KnowledgeBaseList'
import CommonTitle from '@/components/CommonTitle'
import Pagination from '@/components/Pagination'
import merge from "webpack-merge"
export default {
  components: {
    LeftTypeChoose,
    CommonTitle,
    KnowledgeBaseList,
    Pagination
  },
  data() {
    return {
      dataList: [],
      pageSize: 20,
      total: 0,
      pageNumber: parseInt(this.$route.query.pager) || 1,
      title: '社会团体标准化专业人员公共服务平台',
      api: 'RegulationsManagementList',
      value: null
    }
  },
  watch: {
    '$route.params.type': {
      handler(n, o) {
        if(n == 'POLICY') {
          this.title = '政策法规 - 社会团体标准化专业人员公共服务平台'
          this.api = 'RegulationsManagementList'
        }else if(n == 'STANDARD') {
          this.title = '标准 - 社会团体标准化专业人员公共服务平台'
          this.api = 'StandardList'
        }
        else if(n == 'BOOK') {
          this.title = '书籍 - 社会团体标准化专业人员公共服务平台'
          this.api = 'BookList'
        }
        else if(n == 'COURSE') {
          this.api = 'TrainingCoursesList';
          this.title = '课程 - 社会团体标准化专业人员公共服务平台'
        }
        if(n != o && o) {
          this.$router.replace({
            query: merge(this.$route.query, { pager: 1 }),
          });
          this.value = null;
          this.dataList = [];
          this.pageNumber = 1;
        }
        this.showPage()
      },
      immediate: true
    },
  },
  methods: {
    showPage() {
      this.$API[this.api]({
        pageSize: this.pageSize,
        pageNum: this.pageNumber,
        keyword: this.value,
        state: 1
      }).then(res =>{
        if(res.data.code == 200) {
          res.data.data.list.forEach(item => {
            item.coverAddress = item.coverAddress ? this.baseUrl + item.coverAddress : null;
            item.picture = item.picture ? this.baseUrl + item.picture : null;
            item.coverUrl = item.coverUrl ? this.baseUrl + item.coverUrl : null;
          })
          if(res.data.data.list.length == 0 && this.pageNumber != 1) {
            this.pageNumber = 1;
            this.$router.replace({
              query: merge(this.$route.query, { pager: 1 }),
            });
            this.showPage()
          }
          this.dataList = res.data.data.list;
          this.total = res.data.data.total
        }
      })
    },
    keydownClick(e) {
      if(e.keyCode == 13) {
        this.searchClick()
      }
    },
    searchClick() {
      if(!this.value || !this.value.trim()) {
        return this.$message.error('请输入搜索内容')
      }else {
        this.$router.replace({
          query: merge(this.$route.query, { pager: 1 }),
        });
        this.pageNumber = 1;
        this.showPage()
      }
    },
    inputClick(e) {
      if(!e) {
        this.$router.replace({
          query: merge(this.$route.query, { pager: 1 }),
        });
        this.value = null;
        this.pageNumber = 1;
        this.showPage()
      }
    }
  }
}
</script>

<style lang='scss'>
  #knowledgeBase {
    width: 1200px;
    margin: 20px auto 50px;
    padding: 0 10px;

    .content {
      width: 940px;
      margin-left: 240px;

      .common_title {
        h3 {
          line-height: 40px;
        }
      }
  
      .inner {
        background: #fff;
        border: 1px solid #ebebeb;
        border-radius: 10px;
        overflow: hidden;

        .common_knowledge_cards {
          border-top: 0;
          border-left: 0;
          border-right: 0;

          & > div {
            width: 760px;
          }
        }
      }
    }
  }
</style>