<template>
  <div class="left_type_choose">
    <div 
      v-for="item in typeList[type]" 
      :key="item.type"
      :class="{'active': tabIndex == item.type}"
      @click="eventClick(item.type)">
      <svg-icon :icon-class="item.icon"></svg-icon>
      <span class="title">{{ item.title }}</span>
      <p><span>{{ numData[item.num] }}</span></p>
    </div>
  </div>
</template>

<script>
import merge from 'webpack-merge'
export default {
  props: ['type'],
  data() {
    return {
      typeList: {
        knowledgeBase: [
          { icon: 'rule', title: '政策法规', num: 'regulationCount', type: 'POLICY' },
          { icon: 'standard', title: '标准', num: 'standardCount', type: 'STANDARD' },
          { icon: 'book', title: '书籍', num: 'bookCount', type: 'BOOK' },
          { icon: 'course', title: '课程', num: 'courseCount', type: 'COURSE' },
        ]
      },
      tabIndex: null,
      numData: {}
    }
  },
  watch: {
    '$route.params.type': {
      handler(n, o) {
        if(n) this.tabIndex = n;
      },
      immediate: true
    }
  },
  mounted() {
    if(!this.$route.params.type) {
      this.tabIndex = this.typeList[this.type][0].type
      this.$router.replace({
        params: merge(this.$route.params, { type: this.typeList[this.type][0].type }),
      });
    } 
    this.showPage()
  },
  methods: {
    eventClick(type) {
      this.tabIndex = type;
      this.$router.replace({
        params: merge(this.$route.params, { type: type }),
        query: merge(this.$route.query, { pager: 1 }),
      });
    },
    
    showPage() {
      this.$API.KnowledgeBaseTotal().then(res => {
        if(res.data.code == 200) {
          this.numData = res.data.data
        } 
      })
    }
  }

}
</script>

<style lang='scss'>
  .left_type_choose {
    padding: 10px;
    border: 1px solid #ebebeb;
    border-radius: 10px;
    background: #ffffff;
    width: 220px;
    align-items: center;
    position: fixed;

    & > div {
      width: 200px;
      height: 40px;
      background: #ffffff;
      border-radius: 4px;
      display: flex;
      margin-bottom: 5px;
      padding: 10px 12px;
      color: #606266;
      cursor: pointer;

      &:last-child {
        margin-bottom: 0;
      }

      &:hover {
        background: #0072ef;
        color: #FFFFFF;

        .svg-icon {
          color: #FFFFFF;
        }

        & > p > span {
          background: #4d9df4;
        }
        
      }

      .svg-icon {
        width: 20px;
        height: 20px;
        margin-right: 10px;
        color: #606266;
      }

      & > span {
        display: block;
        width: 60px;
        margin-right: 10px;
        font-size: 14px;
        font-weight: 600;

      }

      & > p {
        width: 80px;
        height: 20px;
        line-height: 20px;
        text-align: right;

        & > span {
          display: inline-block;
          padding: 0 15px;
          height: 18px;
          line-height: 18px;
          background: #f3f4f6;
          border-radius: 9px;
          font-size: 12px;
        }
      }
    }

    .active {
      background: #0072ef;
      color: #FFFFFF;

      .svg-icon {
        color: #FFFFFF;
      }

      & > p > span {
        background: #4d9df4;
      }
    }
  }
</style>