<template>
  <div class="knowledgeBaseList">
    <CommonKnowledgeCards v-for="item in dataList" :key="item.id" :data="item"></CommonKnowledgeCards>
  </div>
</template>

<script>
import CommonKnowledgeCards from '@/components/CommonKnowledgeCards'
export default {
  props: ['dataList'],
  components: {
    CommonKnowledgeCards
  }
}
</script>

<style lang='scss'>
  .knowledgeBaseList {
    .common_knowledge_cards {
      border-radius: 0;
      
      & > div {
        & > h3 {
          line-height: 25px;
        }
      }
    }
  }
</style>